import React from "react";
import "./login.scss";
import logoNZT from "../../assets/nzt/authLogo.svg";
import logoJIN from "../../assets/jin/authLogo.svg";
import LoginForm from "../../components/authForms/LoginForm";

export default function LoginPage() {
  const version = process.env.REACT_APP_VERSION;  

  return (
    <div className={`auth ${version === "NZT" ? "nzt" : "jin"}`}>
      <div className="group">
        <img className={"logo"} src={version === "NZT" ? logoNZT : logoJIN} alt="logo" />
        <div className="bg">
          <LoginForm/>
        </div>
      </div>
    </div>
  );
}
