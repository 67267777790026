import React from "react";
import "./informationModal.scss";
import { Modal, UnorderedList, ListItem } from "@carbon/react";
import { useTranslation } from "react-i18next";
import { information } from "../../consts/information";

export default function InformationModal({
  informationModalVisibility,
  setInformationModalVisibility,
}) {
  const { t } = useTranslation();

  return (
    <Modal
      open={informationModalVisibility}
      onRequestClose={() => setInformationModalVisibility(false)}
      modalHeading={t("main.tableInformation.heading")}
      modalLabel={t("main.tableInformation.label")}
      passiveModal
    >
      <UnorderedList>
        {information.map((item, index) => (
          <ListItem key={index}>
            <span>
              <b>{t(item.title)}</b> - {t(item.description)}
            </span>
          </ListItem>
        ))}
      </UnorderedList>
    </Modal>
  );
}
