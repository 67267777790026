export const chartDataMapper = (filteredData) => {
  if (!filteredData || !filteredData?.length) {
    return { lineChartData: [], barChartData: [] };
  }

  const chartDataMap = filteredData?.reduce(
    (acc, cur, index) => {
      const { Date: date, AllinEVUsd, NetWinUsd, PlayerID } = cur;

      const { cumulativeMap, pidsAmountMap } = acc;

      const prevCumulativeValue =
        cumulativeMap.get(date) || index > 0
          ? cumulativeMap.get(filteredData[index - 1].Date)
          : { allinEV: 0, netWin: 0 };

      const updatedValue = {
        allinEV: prevCumulativeValue.allinEV + AllinEVUsd,
        netWin: prevCumulativeValue.netWin + NetWinUsd,
      };

      cumulativeMap.set(date, updatedValue);

      const prevPidsAmountSet = pidsAmountMap.get(date) || new Set();

      prevPidsAmountSet.add(PlayerID);
      pidsAmountMap.set(date, prevPidsAmountSet);

      return acc;
    },
    { cumulativeMap: new Map(), pidsAmountMap: new Map() }
  );

  const lineChartData = Array.from(chartDataMap.cumulativeMap).flatMap(
    ([date, { allinEV, netWin }]) => [
      { group: "All-in EV, $", date, value: Number(allinEV).toFixed(2) },
      { group: "Net win, $", date, value: Number(netWin).toFixed(2) },
    ]
  );

  const barChartData = Array.from(chartDataMap.pidsAmountMap).map(
    ([date, pidSet]) => ({
      group: "PID",
      date,
      value: [...pidSet].length,
    })
  );

  return {
    lineChartData,
    barChartData,
  };
};
