import React, { useState, useEffect } from "react";
import "./templateModal.scss";
import { TextInput, Button } from "@carbon/react";
import { Edit, TrashCan } from "@carbon/react/icons";
import { useTranslation } from "react-i18next";
import { useSetTemplate } from "../../hooks/useSetTemplate";
import { useDeleteTemplate } from "../../hooks/useDeleteTemplate";
import { useGetTemplate } from "../../hooks/useGetTemplate";

export default function TemplateItem({
  index,
  item,
  templatesValues,
  setTemplatesValues,
  toBeClosed,
}) {
  const { t } = useTranslation();

  const [newName, setNewName] = useState(item);
  const [validation, setValidation] = useState({
    invalid: false,
    invalidText: "",
  });

  const [toBeConfirmed, setToBeConfirmed] = useState("");

  const mutationSet = useSetTemplate();
  const mutationDelete = useDeleteTemplate();
  const mutationGet = useGetTemplate();
  const mutationDeleteToEdit = useDeleteTemplate();

  const handleCancelChanges = () => {
    setToBeConfirmed("");

    if (newName !== item) {
      setNewName(item);
    }
  };

  const handleConfirmChanges = (template) => {
    switch (toBeConfirmed) {
      case "edit":
        setTemplatesValues((prevItems) =>
          prevItems.map((str, ind) => (ind === index ? newName : str))
        );
        mutationGet.mutate({ key: template });

        break;
      case "delete":
        setTemplatesValues((prevItems) =>
          prevItems.filter((item) => item !== template)
        );
        mutationDelete.mutate({ key: template });
        mutationSet.mutate({
          key: "customUserTemplateName",
          value: JSON.stringify(
            templatesValues.filter((item) => item !== template)
          ),
        });
        break;

      default:
        break;
    }

    setToBeConfirmed("");
  };

  useEffect(() => {
    if (mutationGet.isSuccess) {
      const templateValue = mutationGet.data?.data?.data?.value;

      mutationDeleteToEdit.mutate({ key: item });

      mutationSet.mutate({
        key: newName,
        value: JSON.stringify(templateValue),
      });

      mutationSet.mutate({
        key: "customUserTemplateName",
        value: JSON.stringify(templatesValues),
      });
    }
  }, [mutationGet.isSuccess]);

  useEffect(() => {
    handleCancelChanges();
  }, [toBeClosed]);

  return (
    <div className="template_item_edit">
      <div className="template_item_edit_name">
        <TextInput
          labelText=""
          maxCount={50}
          enableCounter={toBeConfirmed === "edit"}
          value={newName}
          onChange={(e) => setNewName(e.target.value)}
          id={`text-input-${index}`}
          readOnly={!(toBeConfirmed === "edit")}
        />
      </div>
      {!toBeConfirmed ? (
        <div className="template_item_edit_buttons">
          <Button
            size="md"
            onClick={() => setToBeConfirmed("edit")}
            hasIconOnly
            kind="secondary"
            renderIcon={Edit}
            iconDescription={t("main.createDeleteTemplateModal.changeTemplateEdit")}
          />
          <Button
            size="md"
            kind="secondary"
            hasIconOnly
            renderIcon={TrashCan}
            iconDescription={t("main.createDeleteTemplateModal.changeTemplateDelete")}
            onClick={() => setToBeConfirmed("delete")}
          />
        </div>
      ) : (
        <div className="template_item_edit_buttons_confirm">
          <Button size="md" kind="secondary" onClick={handleCancelChanges}>
          {t("main.createDeleteTemplateModal.changeTemplateCancel")}
          </Button>
          {toBeConfirmed === "edit" ? (
            <Button
              size="md"
              kind="secondary"
              disabled={
                newName.trim() !== "" &&
                !templatesValues.some(
                  (item, ind) => item === newName && ind !== index
                )
                  ? false
                  : true
              }
              onClick={() => handleConfirmChanges(item)}
            >
              {t("main.createDeleteTemplateModal.changeTemplateConfirm")}
            </Button>
          ) : (
            <Button
              size="md"
              kind="danger"
              onClick={() => handleConfirmChanges(item)}
            >
              {t("main.createDeleteTemplateModal.changeTemplateConfirm")}
            </Button>
          )}
        </div>
      )}
    </div>
  );
}
