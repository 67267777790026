const version = process.env.REACT_APP_VERSION;

export const information = [
  {
    title: "main.dataTable.game_type",
    description: "main.tableInformation.gameTypeDesc",
  },
  {
    title: "main.dataTable.hands",
    description: "main.tableInformation.handsDesc",
  },
  {
    title: "NET win, $",
    description: "main.tableInformation.netWinUSDDesc",
  },
  {
    title: "All-in EV/100, $",
    description: "main.tableInformation.allInUSDDesc",
  },
  {
    title: "main.dataTable.fuel",
    description: "main.tableInformation.fuelDesc",
  },
  {
    title: "BB/100",
    description: "main.tableInformation.BB/100Desc",
  },
  {
    title: "EV BB/100",
    description: "main.tableInformation.EVBB/100Desc",
  },
  {
    title: "main.dataTable.HUHands",
    description: "main.tableInformation.HUhandsDesc",
  },
  {
    title: "%HU",
    description: "main.tableInformation.HUhandsPercentDesc",
  },
  {
    title: "main.dataTable.errorsOperator",
    description: "main.tableInformation.errorsOperatorDesc",
  },
  {
    title: "main.dataTable.error",
    description: "main.tableInformation.errorsDesc",
  },
  {
    title: "main.dataTable.R/BTables",
    description: "main.tableInformation.R/BTablesDesc",
  },
  {
    title: "main.dataTable.R/BTablesPercent",
    description: "main.tableInformation.R/BTablesPercentDesc",
  },
  {
    title: "main.dataTable.poker_network",
    description: "main.tableInformation.pokerNetworkDesc",
  },
  {
    title: "main.dataTable.bbChips",
    description: "main.tableInformation.BBChipsDesc",
  },
  {
    title: "main.dataTable.login",
    description: `${version === "NZT" ? "main.tableInformation.loginNZTDesc" : "main.tableInformation.loginJINDesc"}`,
  },
  {
    title: "main.dataTable.PID",
    description: "main.tableInformation.PIDDesc",
  },
  {
    title: "main.dataTable.club",
    description: "main.tableInformation.clubDesc",
  },
  {
    title: "main.dataTable.leagueID",
    description: "main.tableInformation.leagueIDDesc",
  },
  {
    title: "main.dataTable.superleagueID",
    description: "main.tableInformation.superleagueIDDesc",
  },
  {
    title: "main.dataTable.netWinChips",
    description: "main.tableInformation.netWinChipsDesc",
  },
  {
    title: "main.dataTable.allInChips",
    description: "main.tableInformation.allInChipsDesc",
  },
];
