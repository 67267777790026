import React, { useMemo, useState } from "react";
import { LineChart, BubbleChart, SimpleBarChart } from "@carbon/charts-react";
import { ContentSwitcher, Switch } from "@carbon/react";
import "@carbon/charts/styles.css";
import "./incomeChart.scss";
import { useTranslation } from "react-i18next";
import { chartDataMapper } from "../../../mappers/chartDataMapper";

export default function IncomeChart({ isPending, filteredData }) {
  const { t, i18n } = useTranslation();
  const version = process.env.REACT_APP_VERSION;

  const [isLineChart, setIsLineChart] = useState(true);

  const { lineChartData, barChartData } = useMemo(
    () => chartDataMapper(filteredData),
    [filteredData]
  );

  const optionsLine = {
    title: `${t("main.chartTitleLine")}`,
    data: {
      loading: isPending,
    },
    axes: {
      bottom: {
        mapsTo: "date",
        scaleType: "time",
        ticks: {
          formatter: (e) =>
            e.toLocaleDateString(i18n.language, {
              month: "short",
              day: "numeric",
            }),
        },
        limitDomainToBins: true,
      },
      left: {
        mapsTo: "value",
        scaleType: "linear",
        ticks: {
          formatter: (axisValue) => `${axisValue / 1000}k`,
        },
      },
    },
    tooltip: {
      showTotal: false,
      valueFormatter: (e, t) =>
        t === "x-value"
          ? e.toLocaleDateString(i18n.language, {
              month: "long",
              day: "numeric",
            })
          : t === "y-value"
          ? e.toLocaleString(i18n.language)
          : e,
    },
    color: {
      scale: {
        "Net win, $": "#24A148",
        "All-in EV, $": "#FA4D56",
      },
    },
    legend: {
      alignment: "right",
      position: "bottom",
    },
    points: {
      enabled: false,
      radius: 0,
    },
    height: "400px",
    toolbar: {
      controls: [{ type: "Make fullscreen" }],
    },
  };

  const optionsBubble = {
    title: `${t("main.chartTitleLine")}`,
    data: {
      loading: isPending,
    },
    axes: {
      bottom: {
        mapsTo: "date",
        scaleType: "time",
        ticks: {
          formatter: (e) =>
            e.toLocaleDateString(i18n.language, {
              month: "short",
              day: "numeric",
            }),
        },
        limitDomainToBins: false,
      },
      left: {
        mapsTo: "value",
        scaleType: "linear",
        ticks: {
          formatter: (axisValue) => `${axisValue / 1000}k`,
        },
      },
    },
    tooltip: {
      showTotal: false,
      valueFormatter: (e, t) =>
        t === "x-value"
          ? e.toLocaleDateString(i18n.language, {
              month: "long",
              day: "numeric",
            })
          : t === "y-value"
          ? e.toLocaleString(i18n.language)
          : e,
    },
    color: {
      scale: {
        "Net win, $": "#24A148",
        "All-in EV, $": "#FA4D56",
      },
    },
    legend: {
      alignment: "right",
      position: "bottom",
    },
    height: "400px",
    toolbar: {
      controls: [{ type: "Make fullscreen" }],
    },
  };

  const optionsBar = {
    title: `${t("main.chartTitleBar")}`,
    data: {
      loading: isPending,
    },
    axes: {
      bottom: {
        mapsTo: "date",
        scaleType: "time",
        ticks: {
          formatter: (e) =>
            e.toLocaleDateString(i18n.language, {
              month: "short",
              day: "numeric",
            }),
        },
        limitDomainToBins: true,
      },
      left: {
        mapsTo: "value",
      },
    },
    tooltip: {
      valueFormatter: (e, t) =>
        t === "x-value"
          ? e.toLocaleDateString(i18n.language, {
              month: "long",
              day: "numeric",
            })
          : t === "y-value"
          ? e.toLocaleString(i18n.language)
          : e,
    },
    color: {
      scale: {
        "PID": "#0086C9",
      },
    },
    legend: {
      enabled: false,
      alignment: "right",
      position: "bottom",
    },
    height: "400px",
    toolbar: {
      controls: [{ type: "Make fullscreen" }],
    },
  };

  return (
    <div className="lineChart">
      <div className={`lineChart_choose ${version === "NZT" ? "nzt" : "jin"}`}>
        <ContentSwitcher
          onChange={(obj) => {
            if (obj.index === 1) {
              setIsLineChart(false);
            } else setIsLineChart(true);
          }}
        >
          <Switch name="one" text={t("main.lineChart")} />
          <Switch name="two" text={t("main.barChart")} />
        </ContentSwitcher>
      </div>
      {isLineChart ? (
        <>
          {lineChartData.length > 3 ? (
            <LineChart data={lineChartData} options={optionsLine}></LineChart>
          ) : (
            <BubbleChart
              data={lineChartData}
              options={optionsBubble}
            ></BubbleChart>
          )}
        </>
      ) : (
        <SimpleBarChart data={barChartData} options={optionsBar} />
      )}
    </div>
  );
}
