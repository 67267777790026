import React, { useState, useEffect, memo } from "react";
import "./templateModal.scss";
import { Modal, TextInput, Button } from "@carbon/react";
import { useTranslation } from "react-i18next";
import { useSetTemplate } from "../../hooks/useSetTemplate";
import TemplateItem from "./TemplateItem";

const CreateTemplateModal = memo(
  ({
    createModalVisibility,
    setCreateModalVisibility,
    templatesValues,
    setTemplatesValues,
  }) => {
    const { t } = useTranslation();

    const [templateName, setTemplateName] = useState("");
    const [validation, setValidation] = useState({
      invalid: false,
      invalidText: "",
    });

    const [toBeClosed, setToBeClosed] = useState(false);

    const mutationSetItem = useSetTemplate();
    const mutationSetList = useSetTemplate();

    const onCreateTemplate = () => {
      if (
        templateName.trim() !== "" &&
        !templatesValues.some((item) => item === templateName)
      ) {
        setTemplatesValues((prevValues) => [...prevValues, templateName]);

        mutationSetItem.mutate({
          key: templateName,
          value: localStorage.getItem("storage"),
        });

        setTemplateName("");
      } else if (templateName.trim() === "") {
        setValidation({
          invalid: true,
          invalidText:
            "authentication.validationErrors.noTemplateNameValidation",
        });
      } else if (templatesValues.some((item) => item === templateName)) {
        setValidation({
          invalid: true,
          invalidText: "authentication.validationErrors.templateNameValidation",
        });
      } else {
        setValidation({ invalid: false, invalidText: "" });
      }
    };

    useEffect(() => {
      if (templateName.trim() === "") {
        setValidation({
          invalid: true,
          invalidText:
            "authentication.validationErrors.noTemplateNameValidation",
        });
      }
      if (templatesValues.some((item) => item === templateName)) {
        setValidation({
          invalid: true,
          invalidText: "authentication.validationErrors.templateNameValidation",
        });
      } else {
        setValidation({ invalid: false, invalidText: "" });
      }
    }, [templateName, setValidation]);

    useEffect(() => {
      if (mutationSetItem.isSuccess) {
        mutationSetList.mutate({
          key: "customUserTemplateName",
          value: JSON.stringify(templatesValues),
        });
      }
    }, [mutationSetItem.isSuccess]);

    return (
      <Modal
        open={createModalVisibility}
        aria-label=""
        size="sm"
        passiveModal
        hasScrollingContent={true}
        onRequestClose={() => {
          setCreateModalVisibility(false);
          setToBeClosed((prevValue) => !prevValue);
          setValidation({ invalid: false, invalidText: "" });
        }}
      >
        <div className="create_template">
          <h3>{t("main.createDeleteTemplateModal.createTitle")}</h3>
          <p>
          {t("main.createDeleteTemplateModal.createDescription")}
          </p>
          <div className="create_template_input">
            <TextInput
              data-modal-primary-focus
              invalid={validation.invalid}
              invalidText={
                validation.invalidText ? t(validation.invalidText) : ""
              }
              maxCount={50}
              enableCounter={true}
              id="text-input-create"
              labelText={t("main.createDeleteTemplateModal.createInputLabel")}
              placeholder={t("main.createDeleteTemplateModal.createInputPlaceholder")}
              value={templateName}
              onChange={(e) => setTemplateName(e.target.value)}
              disabled={templatesValues.length >= 5 ? true : false}
            />
            <Button
              onClick={onCreateTemplate}
              size="md"
              disabled={templatesValues.length >= 5 ? true : false}
            >
              {t("main.createDeleteTemplateModal.createButton")}
            </Button>
          </div>
        </div>
        <div className="change_template">
          {templatesValues.length > 0 && (
            <div className="change_template_items">
              <h3>{t("main.createDeleteTemplateModal.changeTemplateTitle")}</h3>
              <p>
              {t("main.createDeleteTemplateModal.changeTemplateDescription")}
              </p>
              {templatesValues.map((item, index) => (
                <TemplateItem
                  key={index}
                  item={item}
                  index={index}
                  templatesValues={templatesValues}
                  setTemplatesValues={setTemplatesValues}
                  toBeClosed={toBeClosed}
                />
              ))}
            </div>
          )}
        </div>
      </Modal>
    );
  }
);

export default CreateTemplateModal;
