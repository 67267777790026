export const presetTemplates = [
  {
    name: "main.presetTemplates.totalData",
    value: {
      selectedRowKeys: [],
      columns: [
        {
          visibleIndex: 0,
          dataField: "PokerNetwork",
          name: "Poker network",
          dataType: "string",
          width: "auto",
          groupIndex: 0,
          visible: true,
        },
        {
          visibleIndex: 1,
          dataField: "BigBlind",
          name: "Big Blind (chips)",
          dataType: "number",
          width: "auto",
          groupIndex: 1,
          visible: true,
        },
        {
          visibleIndex: 2,
          dataField: "GameType",
          name: "Game type",
          dataType: "string",
          width: 202,
          visible: true,
        },
        {
          visibleIndex: 3,
          dataField: "UserID",
          name: "Login",
          dataType: "string",
          width: "auto",
          visible: false,
        },
        {
          visibleIndex: 4,
          dataField: "PlayerID",
          name: "PID",
          dataType: "string",
          width: "auto",
          visible: false,
        },
        {
          visibleIndex: 5,
          dataField: "ClubID",
          name: "Club",
          dataType: "string",
          width: "auto",
          visible: false,
        },
        {
          visibleIndex: 6,
          dataField: "LeagueID",
          name: "League ID",
          dataType: "string",
          width: "auto",
          visible: false,
        },
        {
          visibleIndex: 7,
          dataField: "SuperLeagueID",
          name: "SuperLeague ID",
          dataType: "string",
          visible: false,
          width: "auto",
        },
        {
          visibleIndex: 8,
          dataField: "Date",
          name: "Date",
          dataType: "date",
          visible: false,
        },
        {
          visibleIndex: 9,
          dataField: "Hands",
          name: "Hands",
          dataType: "number",
          width: "11.070%",
          visible: true,
        },
        {
          visibleIndex: 10,
          dataField: "NetWinUsd",
          name: "Net win, $",
          dataType: "number",
          width: "10.607%",
          visible: true,
        },
        {
          visibleIndex: 11,
          dataField: "AllinEVUsd",
          name: "all-in EV, $",
          dataType: "number",
          width: "9.911%",
          visible: true,
        },
        {
          visibleIndex: 12,
          dataField: "NetWin",
          name: "Net win (chips)",
          dataType: "number",
          visible: false,
        },
        {
          visibleIndex: 13,
          dataField: "AllinEV",
          name: "all-in EV (chips)",
          dataType: "number",
          visible: false,
        },
        {
          visibleIndex: 16,
          dataField: "Fuel",
          name: "Fuel",
          dataType: "number",
          width: "11.012%",
          visible: true,
        },
        {
          visibleIndex: 14,
          dataField: "NetWinrate",
          name: "bb/100",
          dataType: "number",
          width: "10.549%",
          visible: true,
        },
        {
          visibleIndex: 15,
          dataField: "AllinEVWinrate",
          name: "EV bb/100",
          dataType: "number",
          width: "11.070%",
          visible: true,
        },
        {
          visibleIndex: 21,
          dataField: "HandsHU",
          name: "HU hands",
          dataType: "number",
          visible: false,
        },
        {
          visibleIndex: 18,
          dataField: "HUPercent",
          name: "%HU",
          dataType: "number",
          width: "6.086%",
          visible: true,
        },
        {
          visibleIndex: 22,
          dataField: "HandsOperatorErrors",
          name: "Errors operator",
          dataType: "number",
          visible: false,
        },
        {
          visibleIndex: 19,
          dataField: "Error.percent",
          name: "Error",
          dataType: "number",
          width: "6.607%",
          visible: true,
        },
        {
          visibleIndex: 20,
          dataField: "redBlackTables",
          name: "Red/Black tables",
          dataType: "number",
          visible: false,
        },
        {
          visibleIndex: 17,
          dataField: "redBlackTablesPercent",
          name: "Red/Black tables percent",
          dataType: "number",
          width: "7.767%",
          visible: true,
        },
      ],
      allowedPageSizes: [12, 25, 50],
      filterPanel: {
        filterEnabled: true,
      },
      filterValue: null,
      pageIndex: 0,
      pageSize: 25,
      searchText: "",
    },
  },
  {
    name: "main.presetTemplates.onLogin",
    value: {
      allowedPageSizes: [12, 25, 50],
      columns: [
        {
          visibleIndex: 1,
          dataField: "PokerNetwork",
          name: "Poker network",
          dataType: "string",
          width: "auto",
          groupIndex: 0,
          lastSortOrder: "asc",
          sortOrder: "desc",
          visible: true,
        },
        {
          visibleIndex: 0,
          dataField: "BigBlind",
          name: "Big Blind (chips)",
          dataType: "number",
          width: "auto",
          groupIndex: 1,
          sortOrder: "desc",
          visible: true,
        },
        {
          visibleIndex: 5,
          dataField: "GameType",
          name: "Game type",
          dataType: "string",
          width: 137,
          visible: true,
        },
        {
          visibleIndex: 3,
          dataField: "UserID",
          name: "Login",
          dataType: "string",
          width: 115,
          visible: true,
        },
        {
          visibleIndex: 4,
          dataField: "PlayerID",
          name: "PID",
          dataType: "string",
          width: 103,
          visible: false,
        },
        {
          visibleIndex: 6,
          dataField: "ClubID",
          name: "Club",
          dataType: "string",
          width: 118,
          visible: false,
        },
        {
          visibleIndex: 7,
          dataField: "LeagueID",
          name: "League ID",
          dataType: "string",
          width: 52,
          visible: false,
        },
        {
          visibleIndex: 8,
          dataField: "SuperLeagueID",
          name: "SuperLeague ID",
          dataType: "string",
          visible: false,
        },
        {
          visibleIndex: 2,
          dataField: "Date",
          name: "Date",
          dataType: "date",
          width: "6.716%",
          visible: true,
        },
        {
          visibleIndex: 9,
          dataField: "Hands",
          name: "Hands",
          dataType: "number",
          width: "7.506%",
          visible: true,
        },
        {
          visibleIndex: 10,
          dataField: "NetWinUsd",
          name: "Net win, $",
          dataType: "number",
          width: "7.957%",
          visible: true,
        },
        {
          visibleIndex: 11,
          dataField: "AllinEVUsd",
          name: "all-in EV, $",
          dataType: "number",
          width: "8.634%",
          visible: false,
        },
        {
          visibleIndex: 12,
          dataField: "NetWin",
          name: "Net win (chips)",
          dataType: "number",
          visible: false,
        },
        {
          visibleIndex: 13,
          dataField: "AllinEV",
          name: "all-in EV (chips)",
          dataType: "number",
          visible: false,
        },
        {
          visibleIndex: 14,
          dataField: "Fuel",
          name: "Fuel",
          dataType: "number",
          width: "8.239%",
          visible: true,
        },
        {
          visibleIndex: 15,
          dataField: "NetWinrate",
          name: "bb/100",
          dataType: "number",
          width: "7.844%",
          visible: true,
        },
        {
          visibleIndex: 16,
          dataField: "AllinEVWinrate",
          name: "EV bb/100",
          dataType: "number",
          width: "8.916%",
          visible: true,
        },
        {
          visibleIndex: 18,
          dataField: "HandsHU",
          name: "HU hands",
          dataType: "number",
          width: "7.901%",
          visible: true,
        },
        {
          visibleIndex: 21,
          dataField: "HUPercent",
          name: "%HU",
          dataType: "number",
          width: "6.086%",
          visible: false,
        },
        {
          visibleIndex: 19,
          dataField: "HandsOperatorErrors",
          name: "Errors operator",
          dataType: "number",
          width: "7.957%",
          visible: true,
        },
        {
          visibleIndex: 20,
          dataField: "Error.percent",
          name: "Error",
          dataType: "number",
          width: "7.449%",
          visible: true,
        },
        {
          visibleIndex: 22,
          dataField: "redBlackTables",
          name: "Red/Black tables",
          dataType: "number",
          visible: false,
        },
        {
          visibleIndex: 17,
          dataField: "redBlackTablesPercent",
          name: "Red/Black tables percent",
          dataType: "number",
          sortIndex: 0,
          sortOrder: "asc",
          visible: true,
          width: "9.255%",
        },
      ],
      filterPanel: {
        filterEnabled: true,
      },
      filterValue: null,
      pageIndex: 0,
      pageSize: 25,
      searchText: "",
      selectedRowKeys: [],
    },
  },
  {
    name: "main.presetTemplates.onPID",
    value: {
      selectedRowKeys: [],
      columns: [
        {
          visibleIndex: 1,
          dataField: "PokerNetwork",
          name: "Poker network",
          dataType: "string",
          width: "auto",
          groupIndex: 0,
          lastSortOrder: "asc",
          sortOrder: "desc",
          visible: true,
        },
        {
          visibleIndex: 0,
          dataField: "BigBlind",
          name: "Big Blind (chips)",
          dataType: "number",
          width: "auto",
          groupIndex: 1,
          sortOrder: "desc",
          visible: true,
        },
        {
          visibleIndex: 4,
          dataField: "GameType",
          name: "Game type",
          dataType: "string",
          width: 118,
          visible: true,
        },
        {
          visibleIndex: 5,
          dataField: "UserID",
          name: "Login",
          dataType: "string",
          width: "auto",
          visible: false,
        },
        {
          visibleIndex: 3,
          dataField: "PlayerID",
          name: "PID",
          dataType: "string",
          width: 103,
          visible: true,
        },
        {
          visibleIndex: 6,
          dataField: "ClubID",
          name: "Club",
          dataType: "string",
          width: 118,
          visible: false,
        },
        {
          visibleIndex: 7,
          dataField: "LeagueID",
          name: "League ID",
          dataType: "string",
          width: 52,
          visible: false,
        },
        {
          visibleIndex: 8,
          dataField: "SuperLeagueID",
          name: "SuperLeague ID",
          dataType: "string",
          visible: false,
        },
        {
          visibleIndex: 2,
          dataField: "Date",
          name: "Date",
          dataType: "date",
          width: "6.716%",
          visible: true,
        },
        {
          visibleIndex: 9,
          dataField: "Hands",
          name: "Hands",
          dataType: "number",
          width: "8.409%",
          visible: true,
        },
        {
          visibleIndex: 10,
          dataField: "NetWinUsd",
          name: "Net win, $",
          dataType: "number",
          width: "8.578%",
          visible: true,
        },
        {
          visibleIndex: 11,
          dataField: "AllinEVUsd",
          name: "all-in EV, $",
          dataType: "number",
          width: "8.634%",
          visible: false,
        },
        {
          visibleIndex: 12,
          dataField: "NetWin",
          name: "Net win (chips)",
          dataType: "number",
          visible: false,
        },
        {
          visibleIndex: 13,
          dataField: "AllinEV",
          name: "all-in EV (chips)",
          dataType: "number",
          visible: false,
        },
        {
          visibleIndex: 14,
          dataField: "Fuel",
          name: "Fuel",
          dataType: "number",
          width: "8.352%",
          visible: true,
        },
        {
          visibleIndex: 15,
          dataField: "NetWinrate",
          name: "bb/100",
          dataType: "number",
          width: "9.142%",
          visible: true,
        },
        {
          visibleIndex: 16,
          dataField: "AllinEVWinrate",
          name: "EV bb/100",
          dataType: "number",
          width: "10.214%",
          visible: true,
        },
        {
          visibleIndex: 18,
          dataField: "HandsHU",
          name: "HU hands",
          dataType: "number",
          width: "7.901%",
          visible: true,
        },
        {
          visibleIndex: 21,
          dataField: "HUPercent",
          name: "%HU",
          dataType: "number",
          width: "6.086%",
          visible: false,
        },
        {
          visibleIndex: 19,
          dataField: "HandsOperatorErrors",
          name: "Errors operator",
          dataType: "number",
          width: "7.957%",
          visible: true,
        },
        {
          visibleIndex: 20,
          dataField: "Error.percent",
          name: "Error",
          dataType: "number",
          width: "7.449%",
          visible: true,
        },
        {
          visibleIndex: 22,
          dataField: "redBlackTables",
          name: "Red/Black tables",
          dataType: "number",
          visible: false,
        },
        {
          visibleIndex: 17,
          dataField: "redBlackTablesPercent",
          name: "Red/Black tables percent",
          dataType: "number",
          width: "9.255%",
          visible: true,
          sortIndex: 0,
          sortOrder: "asc",
        },
      ],
      allowedPageSizes: [12, 25, 50],
      filterPanel: {
        filterEnabled: true,
      },
      filterValue: null,
      pageIndex: 0,
      pageSize: 25,
      searchText: "",
    },
  },
  {
    name: "main.presetTemplates.onLeague",
    value: {
      selectedRowKeys: [],
      columns: [
        {
          visibleIndex: 1,
          dataField: "PokerNetwork",
          name: "Poker network",
          dataType: "string",
          width: "auto",
          groupIndex: 0,
          lastSortOrder: "asc",
          sortOrder: "desc",
          visible: true,
        },
        {
          visibleIndex: 0,
          dataField: "BigBlind",
          name: "Big Blind (chips)",
          dataType: "number",
          width: "auto",
          groupIndex: 1,
          sortOrder: "desc",
          visible: true,
        },
        {
          visibleIndex: 2,
          dataField: "GameType",
          name: "Game type",
          dataType: "string",
          width: 212,
          visible: true,
        },
        {
          visibleIndex: 3,
          dataField: "UserID",
          name: "Login",
          dataType: "string",
          width: "auto",
          visible: false,
        },
        {
          visibleIndex: 4,
          dataField: "PlayerID",
          name: "PID",
          dataType: "string",
          width: "auto",
          visible: false,
        },
        {
          visibleIndex: 5,
          dataField: "ClubID",
          name: "Club",
          dataType: "string",
          width: 118,
          visible: false,
        },
        {
          visibleIndex: 6,
          dataField: "LeagueID",
          name: "League ID",
          dataType: "string",
          width: 102,
          visible: true,
        },
        {
          visibleIndex: 7,
          dataField: "SuperLeagueID",
          name: "SuperLeague ID",
          dataType: "string",
          visible: false,
        },
        {
          visibleIndex: 8,
          dataField: "Date",
          name: "Date",
          dataType: "date",
          visible: false,
        },
        {
          visibleIndex: 9,
          dataField: "Hands",
          name: "Hands",
          dataType: "number",
          width: "7.280%",
          visible: true,
        },
        {
          visibleIndex: 10,
          dataField: "NetWinUsd",
          name: "Net win, $",
          dataType: "number",
          width: "9.707%",
          visible: true,
        },
        {
          visibleIndex: 11,
          dataField: "AllinEVUsd",
          name: "all-in EV, $",
          dataType: "number",
          width: "8.634%",
          visible: true,
        },
        {
          visibleIndex: 12,
          dataField: "NetWin",
          name: "Net win (chips)",
          dataType: "number",
          visible: false,
        },
        {
          visibleIndex: 13,
          dataField: "AllinEV",
          name: "all-in EV (chips)",
          dataType: "number",
          visible: false,
        },
        {
          visibleIndex: 16,
          dataField: "Fuel",
          name: "Fuel",
          dataType: "number",
          width: "8.352%",
          visible: true,
        },
        {
          visibleIndex: 14,
          dataField: "NetWinrate",
          name: "bb/100",
          dataType: "number",
          width: "10.549%",
          visible: true,
        },
        {
          visibleIndex: 15,
          dataField: "AllinEVWinrate",
          name: "EV bb/100",
          dataType: "number",
          width: "8.916%",
          visible: true,
        },
        {
          visibleIndex: 22,
          dataField: "HandsHU",
          name: "HU hands",
          dataType: "number",
          visible: false,
        },
        {
          visibleIndex: 20,
          dataField: "HUPercent",
          name: "%HU",
          dataType: "number",
          width: "6.086%",
          visible: false,
        },
        {
          visibleIndex: 17,
          dataField: "HandsOperatorErrors",
          name: "Errors operator",
          dataType: "number",
          visible: true,
          width: "7.957%",
        },
        {
          visibleIndex: 19,
          dataField: "Error.percent",
          name: "Error",
          dataType: "number",
          width: "7.449%",
          visible: true,
        },
        {
          visibleIndex: 21,
          dataField: "redBlackTables",
          name: "Red/Black tables",
          dataType: "number",
          visible: false,
        },
        {
          visibleIndex: 19,
          dataField: "redBlackTablesPercent",
          name: "Red/Black tables percent",
          dataType: "number",
          width: "8.634%",
          visible: true,
          sortIndex: 0,
          sortOrder: "asc",
        },
      ],
      allowedPageSizes: [12, 25, 50],
      filterPanel: {
        filterEnabled: true,
      },
      filterValue: null,
      pageIndex: 0,
      pageSize: 25,
      searchText: "",
    },
  },
  {
    name: "main.presetTemplates.onClub",
    value: {
      selectedRowKeys: [],
      columns: [
        {
          visibleIndex: 1,
          dataField: "PokerNetwork",
          name: "Poker network",
          dataType: "string",
          width: "auto",
          groupIndex: 0,
          lastSortOrder: "asc",
          sortOrder: "desc",
          visible: true,
        },
        {
          visibleIndex: 0,
          dataField: "BigBlind",
          name: "Big Blind (chips)",
          dataType: "number",
          width: "auto",
          groupIndex: 1,
          sortOrder: "desc",
          visible: true,
        },
        {
          visibleIndex: 2,
          dataField: "GameType",
          name: "Game type",
          dataType: "string",
          width: 202,
          visible: true,
        },
        {
          visibleIndex: 3,
          dataField: "UserID",
          name: "Login",
          dataType: "string",
          width: "auto",
          visible: false,
        },
        {
          visibleIndex: 4,
          dataField: "PlayerID",
          name: "PID",
          dataType: "string",
          width: "auto",
          visible: false,
        },
        {
          visibleIndex: 5,
          dataField: "ClubID",
          name: "Club",
          dataType: "string",
          width: 118,
          visible: true,
          sortIndex: 0,
          sortOrder: "asc",
        },
        {
          visibleIndex: 6,
          dataField: "LeagueID",
          name: "League ID",
          dataType: "string",
          width: "auto",
          visible: false,
        },
        {
          visibleIndex: 7,
          dataField: "SuperLeagueID",
          name: "SuperLeague ID",
          dataType: "string",
          visible: false,
          width: "auto",
        },
        {
          visibleIndex: 8,
          dataField: "Date",
          name: "Date",
          dataType: "date",
          visible: false,
        },
        {
          visibleIndex: 9,
          dataField: "Hands",
          name: "Hands",
          dataType: "number",
          width: "8.352%",
          visible: true,
        },
        {
          visibleIndex: 10,
          dataField: "NetWinUsd",
          name: "Net win, $",
          dataType: "number",
          width: "9.707%",
          visible: true,
        },
        {
          visibleIndex: 11,
          dataField: "AllinEVUsd",
          name: "all-in EV, $",
          dataType: "number",
          width: "8.634%",
          visible: true,
        },
        {
          visibleIndex: 12,
          dataField: "NetWin",
          name: "Net win (chips)",
          dataType: "number",
          visible: false,
        },
        {
          visibleIndex: 13,
          dataField: "AllinEV",
          name: "all-in EV (chips)",
          dataType: "number",
          visible: false,
        },
        {
          visibleIndex: 16,
          dataField: "Fuel",
          name: "Fuel",
          dataType: "number",
          width: "8.352%",
          visible: true,
        },
        {
          visibleIndex: 14,
          dataField: "NetWinrate",
          name: "bb/100",
          dataType: "number",
          width: "10.549%",
          visible: true,
        },
        {
          visibleIndex: 15,
          dataField: "AllinEVWinrate",
          name: "EV bb/100",
          dataType: "number",
          width: "8.916%",
          visible: true,
        },
        {
          visibleIndex: 22,
          dataField: "HandsHU",
          name: "HU hands",
          dataType: "number",
          visible: false,
        },
        {
          visibleIndex: 20,
          dataField: "HUPercent",
          name: "%HU",
          dataType: "number",
          width: "6.086%",
          visible: false,
        },
        {
          visibleIndex: 17,
          dataField: "HandsOperatorErrors",
          name: "Errors operator",
          dataType: "number",
          visible: true,
          width: "7.957%",
        },
        {
          visibleIndex: 19,
          dataField: "Error.percent",
          name: "Error",
          dataType: "number",
          width: "7.449%",
          visible: true,
        },
        {
          visibleIndex: 21,
          dataField: "redBlackTables",
          name: "Red/Black tables",
          dataType: "number",
          visible: false,
        },
        {
          visibleIndex: 19,
          dataField: "redBlackTablesPercent",
          name: "Red/Black tables percent",
          dataType: "number",
          width: "8.634%",
          visible: true,
        },
      ],
      allowedPageSizes: [12, 25, 50],
      filterPanel: {
        filterEnabled: true,
      },
      filterValue: null,
      pageIndex: 0,
      pageSize: 25,
      searchText: "",
    },
  },
];
